import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  getProductCanUpsellPrevent,
  getProductHasPrevent,
  useProductsByState,
} from '@pumpkincare/plans';
import { getQuotePartner, getQuotePolicyState, useQuote } from '@pumpkincare/quotes';
import { CAT, DOG, QUOTE_URL_PARAMS_KEY } from '@pumpkincare/shared';
import { ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import InsuranceModal from './insurance-modal';
import Levers from './levers';

import styles from './insurance-picker.css';

import stethoscopePng from './stethoscope.png';

export default function InsurancePicker({
  petPlanData,
  onChange,
  buttonText = 'Add to Cart',
  onAddToCart,
  pet = {},
}) {
  const { data: quoteData } = useQuote();
  const partner = getQuotePartner(quoteData);

  const { punks947PlanPageDesignOptimization } = useFlags();

  const policyState = getQuotePolicyState(quoteData);
  const { data: productsByStateData } = useProductsByState(policyState);

  const productHasPrevent = getProductHasPrevent(productsByStateData); //Maine
  const productCanUpsellPrevent = getProductCanUpsellPrevent(productsByStateData);

  const {
    activeValues,
    insurancePicker: { activePrice, availableLevers, recommendations },
  } = petPlanData;
  const { annualLimit, deductible, copay } = Store.get(QUOTE_URL_PARAMS_KEY) || {};

  const leverRecommendation =
    annualLimit && deductible && copay
      ? {
          annual_limit: { value: annualLimit },
          deductible: { value: deductible },
          copay: { value: copay },
        }
      : recommendations;

  const { petBreedSpecies } = pet;

  const modalData = {
    [DOG]: {
      title: 'Reimbursement Rate',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'This is the percentage of an eligible vet bill your pet insurance plan will reimburse you for.',
        },
        {
          title: 'Why 90%?',
          copy:
            'Because we think every dog parent deserves maximum cash back on eligible vet bills for accidents & illnesses. ' +
            'And that means 90% cash back!',
        },
      ],
      footer: {
        title: 'Arf! Arf!',
        copy: '90% cash back on eligible vet bills kicks in after your annual deductible.',
        img: '/assets/images/swimming-cash-dog.png',
      },
    },

    [CAT]: {
      title: 'Reimbursement Rate',
      sectionItems: [
        {
          title: 'What is it?',
          copy: 'This is the percentage of an eligible vet bill your pet insurance plan will reimburse you for.',
        },
        {
          title: 'Why 90%?',
          copy:
            'Because we think every cat parent deserves maximum cash back on eligible vet bills for accidents & illnesses. ' +
            'And that means 90% cash back!',
        },
      ],
      footer: {
        title: 'Meow! Meow!',
        copy: '90% cash back on eligible vet bills kicks in after your annual deductible.',
        img: '/assets/images/swimming-cash-cat.png',
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.topText}>
          <div className={styles.headerWrapper}>
            <div className={styles.kidney}>
              <img
                alt=''
                src='/assets/images/paw-huck-blue.svg'
                className={styles.pawHuck}
              />
              <img
                alt=''
                src='/assets/images/kidney-1.svg'
                className={styles.bgImg}
              />

              <div className={styles.percentBox}>
                <span className={classNames(styles.percentBoxTitle, Typography.h1)}>
                  90%
                </span>
                <span className={Typography.legalBody}>
                  reimbursement for all eligible <br />
                  vet bills for accidents & illnesses{' '}
                </span>

                <InsuranceModal modalData={modalData[petBreedSpecies] ?? {}} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Levers
        activeValues={activeValues}
        levers={availableLevers}
        recommendations={
          partner === 'internal-plan-recommendation' ? {} : leverRecommendation
        }
        onChange={onChange}
        pet={pet}
      />

      <button
        className={classNames(ButtonStyles.primary, styles.addToCartButton)}
        data-testid='select-this-plan'
        onClick={onAddToCart}
      >
        {`${buttonText} – $${activePrice} / mo`}
      </button>

      {punks947PlanPageDesignOptimization &&
      productHasPrevent &&
      productCanUpsellPrevent ? (
        <div className={styles.wellnessCallout}>
          <div>
            <img alt='' src={stethoscopePng} width={40} height={52} />
          </div>

          <div>
            <span className={Typography.legalBody}>
              <b>Want refunds on wellness care?</b>
              <br />
              Add Preventive Essentials on the next page! It's not insurance, but an
              optional wellness package you can add to your plan.
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
